















import { defineComponent, computed, ref, nextTick, reactive } from "@vue/composition-api";

import { useBlendingStore } from "@/store/useBlendingStore";
import { useLessonStateStore } from "@/store/useLessonStateStore";

import ThePageHeader from "@/components/singletons/ThePageHeader.vue";
import SegmentProgress from "@/components/lessons/shared/SegmentProgress.vue";

// Lesson types
import GridLesson from "@/components/lessons/GridLesson.vue";
import SelectorSequenceLesson from "@/components/lessons/SelectorSequenceLesson.vue";
import ConcatenatorSequenceLesson from "@/components/lessons/ConcatenatorSequenceLesson.vue";
import { SelectorSequenceLesson as SelectorSequenceLessonDomain } from "@/ts/domain/SelectorSequenceLesson";
import { delay } from "@/ts/system/delay";
import { SelectorExercise } from "@/ts/domain/exercises/SelectorExercise";
import { SelectorSequenceExercise } from "@/ts/domain/exercises/SelectorSequenceExercise";
import { SelectorExerciseOption } from "@/ts/domain/exercises/SelectorExerciseOption";

export default defineComponent({
  components: {
    ThePageHeader,
    SegmentProgress,
    GridLesson,
    SelectorSequenceLesson,
    ConcatenatorSequenceLesson
  },

  setup() {
    const blendingStore = useBlendingStore();
    const lessonState = useLessonStateStore(true);

    blendingStore.load();
    lessonState.load();

    const lesson = computed(() => {
      // blendingStore.data.value[2].segments[0].lessons[4] === SelectorSequenceLesson
      return blendingStore.data.value.length
        ? blendingStore.data.value[2].segments[0].lessons[4]
        : null;
    });

    const played = ref<string>("");

    async function start() {
      await delay(1000);

      const l = lesson.value as SelectorSequenceLessonDomain;

      // @ts-ignore
      l.onBeforeExerciseStarted.add(async (sequence: SelectorSequenceExercise) => {
        // subscribing to each sequence
        subscribeToSequenceEvents(sequence);
      });

      function subscribeToSequenceEvents(sequence: SelectorSequenceExercise) {
        sequence.onItemStarted.add(async (selector: SelectorExercise) => {
          // subscribing to each selector
          subscribeToSelectorEvents(selector);
        });

        sequence.onStarted.add(async () => {
          console.error("sequence started");
        });

        sequence.onFinished.add(async () => {
          console.error("sequence finished");
        });

        sequence.onFailed.add(
          async (sequence: SelectorSequenceExercise, wrongAnswers: Set<SelectorExerciseOption>) => {
            console.error("sequence failed");
            console.log(wrongAnswers);
          }
        );
      }

      function subscribeToSelectorEvents(selector: SelectorExercise) {
        selector.onItemStarted.add(async opt => {
          await delay(1000);
          played.value += opt.text + " ";
        });

        selector.onItemFinished.add(async opt => {
          await selector.checkAnswer(selector.active[0]);
          await delay(200);
        });

        selector.onAttemptFinished.add(async () => {
          console.error("Num of attempts: " + selector.currentAttemptNumber);
        });

        selector.onFailed.add(async () => {
          console.error(selector.incorrectAnswersAllTime);
        });

        selector.onFinished.add(async () => {
          console.error("selector finished");
        });

        selector.onCorrectItemSelected.add(async () => {
          console.error("CORRECT");
        });

        selector.onIncorrectItemSelected.add(async () => {
          console.error("INCORRECT");
        });
      }

      // @ts-ignore
      window.__lesson = l;
      await l.start();
    }

    return {
      lesson,
      played,
      start
    };
  }
});
